// @DOC Entry point of the frontend is main.js

// @VUECLI console.log("Using webpack via vue-cli");
// @VITE console.log("Using Vite");

import Vue from "vue";

import "@/main.mjs";
import "@/main.css";
import "@/functions.mjs";

import store from "@/boilerplate/store.mjs";
import router from "@/router.mjs";
import vuetify from "@/plugins/vuetify.js";

import App from "@/App.vue";

var vm = new Vue({
    el: "#app",
    store,
    router,
    vuetify, // added as required in 2.0.7
    mounted() {
        if (ß.USE_VUEX) this.$store.dispatch("server/load_session");
    },
    render: (h) => h(App),
});

/* @DOC
 
 Use a /static file in a template
 <img :src="$app.uri('/some.svg')" height="200px">
 
 Use an asset file in a template
 <img :src="require('@/assets/some.svg')" height="200px">

*/
