<template>
    <div>
        <div v-for="(obj, key, i) in localformat" :key="i">
            <!-- uncommenting this causes a bug, but only in production. TODO investigate. -->
            <!--div>KEY: {{ key }} OBJ: {{ obj }} MODEL-KEY: {{ model[key] }} END</div-->
            <div v-if="isComponent(obj)">
                <component :is="obj.is" v-model="model[key]" v-bind="obj" @input="updateModel" @change="updateModel" v-if="obj.if !== undefined ? obj.if : true" />
            </div>
            <div v-if="isElement(obj)">
                <vuetiform-element v-model="model[key]" :format="format[key]" @input="updateModel" @change="updateModel" />
            </div>
            <div v-if="isPre(obj)">
                <h3>{{ obj.label }}</h3>
                <pre style="text-align: left; white-space: pre-line">
        			{{ model[key] }} 
    			</pre
                >
            </div>
        </div>
    </div>
</template>
<script>
//
// SCHEMA: {{localformat}}
// MODEL:{{model}}
//
// Vuetiform elements
import VuetiformDateAndTime from "@/vuetiform-components/vuetiform-date-and-time.vue";
import VuetiformDaytime from "@/vuetiform-components/vuetiform-daytime.vue";

import VuetiformTimestamp from "@/vuetiform-components/vuetiform-timestamp.vue";
import VuetiformDuration from "@/vuetiform-components/vuetiform-duration.vue";
import VuetiformChipgroup from "@/vuetiform-components/vuetiform-chipgroup.vue";
import VuetiformCheckboxes from "@/vuetiform-components/vuetiform-checkboxes.vue";
import VuetiformRadios from "@/vuetiform-components/vuetiform-radios.vue";
import VuetiformColor from "@/vuetiform-components/vuetiform-color.vue";
import VuetiformComment from "@/vuetiform-components/vuetiform-comment.vue";
import VuetiformList from "@/vuetiform-components/vuetiform-list.vue";
import VuetiformSelect from "@/vuetiform-components/vuetiform-select.vue";
import VuetiformTaggableText from "@/vuetiform-components/vuetiform-taggable-text.vue";
import VuetiformDesignate from "@/vuetiform-components/vuetiform-designate.vue";
import VuetiformInfo from "@/vuetiform-components/vuetiform-info.vue";

import VuetiformDatabaseDatafield from "@/vuetiform-components/vuetiform-database-datafield.vue";

// Vuetifiles
import VuetifilesUpload from "@/vuetifiles-components/vuetifiles-upload.vue";
import VuetifilesList from "@/vuetifiles-components/vuetifiles-list.vue";

// since we use a dynamic vuetify component we must import them as components.
import { VTextField, VTextarea, VSelect, VCombobox, VCheckbox, VColorPicker, VAutocomplete } from "vuetify/lib";

//import { VueEditor } from "vue2-editor";

import evaluate from "@/vuetiform-components/vuetiform-accessor.js";

export default {
    name: "vuetiform-element",
    // again, we must have the vuetify components ready to use for dynamic components
    components: {
        VTextField,
        VTextarea,
        VSelect,
        VCombobox,
        VCheckbox,
        VColorPicker,
        VAutocomplete,
        VuetiformDateAndTime,
        VuetiformDaytime,
        VuetiformTimestamp,
        VuetiformDuration,
        VuetiformChipgroup,
        VuetifilesUpload,
        VuetifilesList,
        VuetiformCheckboxes,
        VuetiformRadios,
        VuetiformColor,
        //VueEditor,
        VuetiformDatabaseDatafield,
        VuetiformComment,
        VuetiformList,
        VuetiformSelect,
        VuetiformTaggableText,
        VuetiformDesignate,
        VuetiformInfo,
    },
    // me must get a format, and a value, where value is the v-model we work with
    props: ["format", "value"],

    data: function () {
        let model = { ...this.value };
        let format = this.updateSchema();
        // apply default value if the format has a default value

        for (let s in format) if (format[s] !== undefined) if (format[s].default !== undefined) if (model[s] === undefined) model[s] = format[s].default;

        return {
            // the internal v-model
            model: model,
            localformat: format,
        };
    },
    mounted() {
        this.updateSchema();
    },
    methods: {
        // this will make sure we have two-way data binding
        updateModel() {
            this.updateSchema();
            this.$emit("input", this.model);
        },
        updateSchema() {
            this.localformat = this.mod(this.format);
            // the comment has to be at the end.
            const comment = this.localformat.comment;
            delete this.localformat.comment;
            this.localformat.comment = comment;
            return this.localformat;
        },
        inDebugMode(obj) {
            if (obj.debug === true && ß.DEBUG) return true;
            return false;
        },
        // we reached a component if it has the is property - defining what kind of UI element it should use.
        isComponent(obj) {
            if (typeof obj !== "object") return false;
            // vuetify elements
            if (obj.is === "v-text-field") return true;
            if (obj.is === "v-textarea") return true;
            if (obj.is === "v-select") return true;
            if (obj.is === "v-combobox") return true;
            if (obj.is === "v-checkbox") return true;
            if (obj.is === "v-file-input") return true;
            if (obj.is === "v-color-picker") return true;
            if (obj.is === "v-autocomplete") return true;

            // vuetiform elements
            if (obj.is === "vuetiform-date-and-time") return true;
            if (obj.is === "vuetiform-daytime") return true;
            if (obj.is === "vuetiform-timestamp") return true;
            if (obj.is === "vuetiform-duration") return true;
            if (obj.is === "vuetifiles-list") return true;
            if (obj.is === "vuetifiles-upload") return true;
            if (obj.is === "vuetiform-chipgroup") return true;
            if (obj.is === "vuetiform-checkboxes") return true;
            if (obj.is === "vuetiform-radios") return true;
            if (obj.is === "vuetiform-color") return true;
            if (obj.is === "vuetiform-comment") return true;
            if (obj.is === "vuetiform-list") return true;
            if (obj.is === "vuetiform-select") return true;
            if (obj.is === "vuetiform-taggable-text") return true;
            if (obj.is === "vuetiform-designate") return true;
            if (obj.is === "vuetiform-info") return true;

            if (obj.is === "vuetiform-database-datafield") return true;
            // others
            //if (obj.is === "vue-editor") return true;
            return false;
        },
        // the element object indicates a recursive dive in
        isElement(obj) {
            if (typeof obj === "object") if (obj.is === undefined) return true;
            return false;
        },
        isPre(obj) {
            if (typeof obj === "object") if (obj.is === "pre") return true;
            return false;
        },
        mod(o) {
            if (!o) return o;

            // as the format is coming from vuex/parent object, we will operate on a deep copy.
            const obj = deepCopy(o);
            //console.log("copy:", JSON.parse(JSON.stringify(obj)));
            //return o;

            // modify the format object to use accessors and conditionals
            modify(obj, this);

            // set the custom field options, validators, regex, etc.
            for (let e in obj) fieldify(obj[e]);

            // This is only relevant for databases .. but necessery for clickable items and dynamic references
            for (const key in obj)
                if (obj[key])
                    if (obj[key].ref) {
                        //if (obj[key].reference === undefined) obj[key].reference = obj[key].ref;
                        if (obj[key].uribase === undefined) obj[key].uribase = "/database/" + obj[key].ref;
                        if (obj[key].prepend === undefined) obj[key].prepend = "mdi-launch";
                    }

            return obj;
        },
    },
};

// this procedure operates on the object reference.
function fieldify(obj) {
    // if the format has a validators filed, modify the format, so we can use our validators
    // rules - array of logic from string
    if (!obj) return;
    if (typeof obj !== "object") return;

    //console.log("fieldify", obj.is);

    let mandatory = obj.mandatory || false;

    if (!obj.rules) obj.rules = [];

    if (obj.mandatory) obj.rules.push(validateMandatoryField);

    // custom server-side defined validation rules
    if (obj.validators) {
        // the validation rules are ß.logic functions
        for (let v of obj.validators.split("|")) {
            if (v === "validateMandatoryField") mandatory = true;
            if (ß.logic[v]) obj.rules.push(ß.logic[v]);
        }
        const count = obj.validators.split("|").length;
        delete obj.validators;
    }

    // custom validation regex
    if (obj.regex && typeof obj.regex === "string") {
        const regex = new RegExp(obj.regex);
        const fn = function checkRegExp(str) {
            if (!str) return true;
            if (regex.test(str) === false) return "##&en Invalid value ##&hu Érvénytelen érték ##";
            return true;
        };
        obj.rules.push(fn);
    }

    if (mandatory) if (obj.label) obj.label = "*" + obj.label;
    if (obj.mandatory) delete obj.mandatory;
}

function validateMandatoryField(a) {
    if (a === undefined || a === null) return "##&en Mandatory field ##&hu Kötelező mező ##";
    return true;
}

function modify(obj, that) {
    if (typeof obj !== "object") return;
    if (Array.isArray(obj)) {
        for (const o of obj) modify(o, that);
        return;
    }

    // take action
    for (const prop in obj) modop(obj, prop, that);
    // recursion if needed
    for (const prop in obj) modify(obj[prop], that);
}

function modop(obj, prop, that) {
    let pointer = that;

    if (prop.startsWith("@")) return console.log(prop);

    // @ switch to the model prefix,
    // $ indicates that this property should be modified.
    if (prop.startsWith("@")) pointer = that.model;
    else if (!prop.startsWith("$")) return;

    let accessor = obj[prop];
    let negate = false;

    if (typeof accessor !== "string") return;

    if (accessor.startsWith("!")) {
        accessor = accessor.substr(1);
        negate = true;
    }

    delete obj[prop];

    let set_value;
    let set_prop = prop.substr(1);

    // ! force to false, if any value
    if (prop.endsWith("!")) {
        set_value = false;
        set_prop = prop.substr(1, prop.length - 2);
    }

    // ? force to true, if any value
    if (prop.endsWith("?")) {
        set_value = true;
        set_prop = prop.substr(1, prop.length - 2);
    }

    const env = {};
    env.$ = that;
    env.ß = ß;

    // the default start-pointer is 'this' but can be the model with @prop (vuetifiles-upload uses that in databases)
    // the this context can always be reached by the $ symbol via env, and the pointer could be an empty object too.
    let value = evaluate(accessor, pointer, env);

    if (negate) if (value === true || value === false) value = !value;

    // handle ! and ?
    if (set_value !== undefined) {
        // if the evaluation returned some value, return true/false
        if (value !== undefined) obj[set_prop] = set_value;
        return;
    }

    obj[set_prop] = deepCopy(value);
}

// structuredClone is not available
function deepCopy(obj) {
    if (obj === undefined) return undefined;
    if (obj === null) return null;
    if (typeof obj !== "object") return obj;

    if (obj instanceof Date) {
        const copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    if (obj instanceof Array) {
        var copy = [];
        for (let i = 0; i < obj.length; i++) copy[i] = deepCopy(obj[i]);
        return copy;
    }
    if (obj instanceof Object) {
        const copy = {};
        for (const attr in obj) if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);

        return copy;
    }
    console.log("Unable to copy", obj);
    return {};
}
</script>
