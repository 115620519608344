<template>
    <div id="database" :key="db">
        <database-card v-if="db" :database="db" />
        <section v-if="!db" class="relative dark-bg">
            <v-container fill-height dark>
                <v-layout>
                    <v-row justify="center" no-gutters >
                        <v-col md="6" lg="5" xl="3" cols="12" class="pa-5 desc-cols" v-for="db in getList()" :key="db">
                            <v-card hover style="max-width: 360px; width: 360px; margin: auto" :color="getColor(db)" @click.native="onClick(db)">
                                <div class="pa-5 img-cont">
                                    <v-responsive :aspect-ratio="10 / 1">
                                        <v-icon>{{ getIcon(db) }}</v-icon> {{ db }}
                                    </v-responsive>
                                    <p></p>
                                </div>
                                <v-card-title class="text-center" style="display: block">{{ getTitle(db) }}</v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-container>
        </section>
    </div>
</template>
<script>
// TODO needs vuetiform

import DatabaseCard from "@/database-components/database-card.vue";

export default {
    name: "database",
    route: { path: "/database/:db?/:id?", icon: "mdi-database", navigation: "database" },
    components: { DatabaseCard },
    data() {
        return {
            db: null,
            selected: {},
        };
    },
    created() {
        this.checkURI();
    },
    methods: {
        getTitle(db) {
            if (this.$store.state.database[db]) if (this.$store.state.database[db].title) return this.$store.state.database[db].title;
            return db.toUpperCase();
        },
        onClick(db) {
            this.db = db;
            if (this.$route.path !== "/database/" + db) this.$router.push("/database/" + db);
        },
        checkURI() {
            if (this.$route.path === "/database" || this.$route.path === "/database/") this.db = null;
            if (this.$route.params.db) if (this.$store.getters["database/getDatabaseList"].includes(this.$route.params.db)) this.db = this.$route.params.db;
        },
        getList() {            
            const list = this.$store.getters["database/getDatabaseList"].sort();
            // will return empty array if there are no databases
            const databases = this.$store.getters["database/getDatabaseDocumentsNameArray"]("databases").sort();
            return [...list.filter(db => !databases.includes(db)), ...databases];
        },
        getColor(db) {
        	const fullaccess = this.$store.getters["database/getDatabaseFullAccess"](db);
            if (!fullaccess) return "#999";
            
            const primary = this.$vuetify.theme.currentTheme.primary;  
            
            const databases = this.$store.getters["database/getDatabaseDocumentsNameArray"]("databases");
            if (databases.includes(db)) return primary;

            if (primary.length === 7) return primary + "90";
            return primary + "9";
        },
        getIcon(db) {
            let icon = "mdi-database";
            const fullaccess = this.$store.getters["database/getDatabaseFullAccess"](db);
            if (fullaccess) icon = "mdi-database-edit";
            return icon;
        },
    },
    watch: {
        "$route.params"(to, from) {
            this.checkURI();
        },
        "$route.params.db"(to, from) {
            this.checkURI();
        },
        "$store.state.database"() {
            this.checkURI();
        },
    },
};
</script>

<style scoped></style>
