<template>
    <div id="datapoints">
        <datapoints-table v-if="showData()" :selection="selection" />
        <v-card v-else pa-10>
            <v-card-title>
                <div class="px-4 pt-4 pb-3 text-h4">Datapoints</div>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="filter" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="getItems()"
                :items-per-page="itemsPerPage"
                :search="search"
                hide-default-footer
                item-key="_id"
                class="elevation-1"
                dense
                @click:row="click"
            >
            </v-data-table>

            <v-card-actions>
                <v-spacer> </v-spacer>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
// TODO needs vuetiform
//
//
import DatapointsTable from "@/datapoints-components/datapoints-table.vue";

export default {
    name: "datapoints",
    route: { path: "/datapoints/:collection?", icon: "mdi-database-outline", navigation: "datapoints" },
    components: { DatapointsTable },
    data() {
        return {
            selection: null,
            name: null,
            search: "",
            itemsPerPage: 100,
            headers: [
                {
                    text: "Name",
                    sortable: false,
                    value: "name",
                },
                { text: "Type", value: "type" },
                { text: "Collection", value: "collection" },
                { text: "Documents", value: "count"}
            ],
        };
    },
    methods: {
        showData() {
            if (!this.selection) return false;
            if (!this.selection.collection) return false;
            return true;
        },
        getItems() {
            return this.$store.state.datapoints.collectors;
        },
        click(item) {
            this.selection = item;
            if (this.$route.path !== "/datapoints/" + this.selection.collection) this.$router.push("/datapoints/" + this.selection.collection);
        },
        checkURI() {
            if (this.$route.path === "/datapoints" || this.$route.path === "/datapoints/" || !this.$route.params.collection) {
                this.selection = null;
                return;
            }

            if (this.selection)
                if (this.$route.path === "/datapoints/" + this.selection.collection || this.$route.path === "/datapoints/" + this.selection.collection + "/") return;

            const collection = this.$route.params.collection;
            this.getItems().forEach((item) => {
                if (item.collection === collection) {
                    this.selection = item;
                }
            });
        },
    },
    watch: {
        "$route.params.collection"(to, from) {
            this.checkURI();
        },
    },
};
</script>

<style scoped></style>
