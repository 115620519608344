<template>
    <div class="text-xs-center">
        <v-dialog v-model="dialog" max-width="500">
            <login_layout/>
        </v-dialog>
    </div>
</template>

<script>
// the login dialog is loaded on App.vue so it can work as a dialog

    
// the selector list
import login_layout from "@/login-components/LoginLayout.vue";

// this.$refs.login_dialog.set_dialog('selector');

export default {
    name: "login-dialog",
    data() {
        return {
            // open the dialog on the login uri path
            dialog: this.$route.path === "/login",
            isDialog: this.withDialog || true,
        };
    },
    props: {
        withDialog: "",
    },
    components: {
        login_layout,
        
    },
    methods: {
        open() {
            this.selected = "selector";
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        set_dialog(arg) {
            this.selected = "selector";
            if (arg === "close") return (this.dialog = false);
            this.selected = arg;
        },
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit("finished");
        },
    },
};
</script>

