import Vue from "vue";
import store from "@/boilerplate/store.mjs";

// socket.io is loaded in the index html file does not work with vue_cli - but needed for compatibility with vite
import io from "socket.io-client";

import VueSocketio from "vue-socket.io-extended";

// @DOC Socketio will only connect if a user is logged in. Override possible.
export default function () {
    const socket_options = ß.SOCKETIO_OPTIONS || {
        autoConnect: ß.SOCKETIO_AUTOCONNECT || false,
    };
    const socket = io(socket_options);
    Vue.use(VueSocketio, socket, { store });
}
