<template>
    <div>
        <v-textarea v-model="text" @input="update" :disabled="disabled" label="##&en Comments ##&hu Megjegyzések ##"></v-textarea>
    </div>
</template>

<script>
export default {
    // vuetiform-database-comment
    name: "vuetiform-comment",
    props: ["value", "disabled"],
    data: function () {
        return {
            text: this.value || this.default,
            comment: null,
        };
    },
    methods: {
        update() {
            this.$emit("input", this.text);
        },
    },
};
</script>
<style scoped></style>
