<template>
    <div>
        <v-icon :dark="dark">mdi-flag</v-icon>
        <v-btn :dark="dark" text @click="set_language('##&en hu ##&hu en ##')">{{ label }}</v-btn>
    </div>
</template>

<script>
import * as axios from "__axios";

// A simple language selector button for two languages

export default {
    name: "language-selector",
    data: () => ({
        //app_languages: Object.keys(ß.APP_LANGUAGES)
    }),
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "##&en Magyarul ##&hu English ##",
        },
    },
    methods: {
        set_language: function (lang) {
            // eslint-disable-next-line
            var url = "/post-lang-" + lang + ".json";
            console.log(lang);
            axios({
                method: "post",
                url: url,
            })
                .then(function (response) {
                    console.log(response);
                    location.reload();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
