<template>
    <v-select
        :key="key"
        :menu-props="menu"
        v-model="model"
        :items="items"
        :clearable="clearable"
        chips
        :label="label"
        :hint="hint"
        @input="update"
        :search-input.sync="search"
        @change="search = ''"
        :prepend-icon="prepend"
        @click:prepend="$router.push(uribase)"
        :disabled="disabled"
    >
        <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" draggable @click="takeAction(data.item)" color="#eee">
                <v-avatar left>
                    <v-icon>{{ modeIcon() }}</v-icon>
                </v-avatar>
                {{ getText(data.item) }}
            </v-chip>
        </template>
        <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title v-html="getText(data.item)"></v-list-item-title>
            </v-list-item-content>
        </template>
        <template v-slot:no-data> - </template>
    </v-select>
</template>

<script>
// most of the code is the same as in vuetiform-select
// but this version is specialised to fully identified id'd

export default {
    name: "vuetiform-universal-select",
    props: ["value", "items", "clearable", "label", "hint", "uribase", "item-urikey", "sortable", "readonly", "disabled", "prepend"],
    data: function () {
        return {
            urikey: this["item-urikey"] || "db",
            model: this.value,
            modes: [{ action: "view", icon: "mdi-circle-outline", ro: true }],
            x: 0,
            menu: {
                closeOnClick: false,
                closeOnContentClick: false,
                disableKeys: true,
                openOnClick: false,
                maxHeight: 300,
                offsetY: true,
                offsetOverflow: true,
                transition: false,
            },
            search: "",
            key: 0,
        };
    },
    mounted() {
        this.getModes();
    },
    methods: {
        getText(item) {
            if (typeof item !== "object") return item;
            if (item[this.urikey]) return item[this.urikey] + " / " + item.text;
            return item.text;
        },
        getModes() {
            const modes = [];
            let ro = false;
            if (this.readonly) ro = true;

            if (this.uribase) modes.push({ action: "open", icon: "mdi-launch", ro });
            else if (ro) modes.push({ action: "open", icon: "mdi-circle-outline", ro: true });
            else modes.push({ action: "select", icon: "mdi-arrow-down-drop-circle", ro: false });

            if (this.sortable) {
                modes.push({ action: "left", icon: "mdi-arrow-left-box", ro: true });
                modes.push({ action: "right", icon: "mdi-arrow-right-box", ro: true });
            }

            modes.push({ action: "remove", icon: "mdi-close-circle", ro: true });

            this.modes = modes;
        },
        update() {
            this.$emit("input", this.model);
        },
        mode() {
            this.x = (this.x + 1) % this.modes.length;
            console.log("mode", this.x, this.modes);
        },
        modeIcon() {
            return this.modes[this.x].icon;
        },
        takeAction(item) {
            const action = this.modes[this.x].action;
            if (this[action]) return this[action](item);
        },
        open(item) {
            if (!this.uribase) return;
            if (item[this.urikey]) this.$router.push(this.uribase + "/" + item[this.urikey] + "/" + item.value);
            else this.$router.push(this.uribase + "/" + (item.value || item));
        },
        remove(item) {
            const index = this.model.indexOf(item.value);
            this.model.splice(index, 1);
            this.key++;
            this.$emit("input", this.model);
        },
        left(item) {
            const index = this.model.indexOf(item.value);
            if (index < 1) return;
            const hold = this.model[index - 1];
            this.model[index - 1] = item.value;
            this.model[index] = hold;
            this.key++;
            this.$emit("input", this.model);
        },
        right(item) {
            const index = this.model.indexOf(item.value);
            if (index + 1 > this.model.length - 1) return console.log("?");
            const hold = this.model[index + 1];
            this.model[index + 1] = item.value;
            this.model[index] = hold;
            this.key++;
            this.$emit("input", this.model);
        },
    },
    watch: {
        uribase() {
            this.getModes();
            this.key++;
        },
    },
};
</script>
<style scoped></style>
