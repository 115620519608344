import Vue from "vue";

// initial state
const state = {
    checkpoints: {},
};

// mutations
const mutations = {
    SOCKET_CHECKPOINTS_UPDATE(state, checkpoints) {
        Vue.set(state, "checkpoints", checkpoints);
    },
};

const getters = {
    // Access with $store.getters["database/getDatabaseList"]
    getCheckpoints: (state, getters) => () => {
        return state.checkpoints;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
