<template>
    <v-list two-line>
        <v-list-item v-for="(item, key) in methods" v-if="item[is]" :key="item.title" @click="click_action(item[is].action)">
            <v-list-item-avatar>
                <v-icon :style="style_color(item.iconColor)" class="grey lighten-2">{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-left">
                <v-list-item-title>{{ item[is].title || key }}</v-list-item-title>
                <v-list-item-subtitle>{{ item[is].subtitle || "" }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-btn icon ripple v-if="has_key(key)"> <v-icon color="green lighten-1">check_circle</v-icon> </v-btn>
                <v-btn icon ripple v-if="has_alert(key)"> <v-icon color="red darken-1">announcement</v-icon> </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
import * as axios from "__axios";

export default {
    name: "login-selector",
    props: ["selected"],
    data() {
        return {
            methods: ß.LOGIN_METHODS,
        };
    },
    methods: {
        style_color: function (hex) {
            return "color:" + hex;
        },
        close() {
            this.$emit("dialog_handler", "close");
        },
        click_action: function (action) {
            //console.log(key, action);
            if (action.charAt(0) === "/") {
                //window.location = "https://" + ß.HOSTNAME + action;
                window.location = action;
            } else this.$emit("dialog_handler", action);
        },
        has_key(key) {
            if (!this.$store.state.server) return false;
            if (!this.$store.state.server.session) return false;
            if (!this.$store.state.server.session.user) return false;

            if (this.$store.state.server.session.user.local) {
                if (key === "password") if (this.$store.state.server.session.user.local.password) return true;
                if (key === "email") if (this.$store.state.server.session.user.local.email) return true;
            }

            if (key === "name") if (this.$store.state.server.session.user.name) return false;

            if (this.$store.state.server.session.user[key]) return true;
        },

        has_alert(key) {
            if (!this.$store.state.server) return false;
            if (!this.$store.state.server.session) return false;
            if (!this.$store.state.server.session.user) return false;

            if (key === "name") if (this.$store.state.server.session.user.name === this.$store.state.server.session.user._id) return true;

            return false;
        },

        noop() {},
    },
    computed: {
        is() {
            if (this.$store.state.server.session) if (this.$store.state.server.session.user) if (this.$store.state.server.session.user._id) return "user";
            return "guest";
        },
        is_user() {
            if (this.$store.state.server.session) if (this.$store.state.server.session.user) if (this.$store.state.server.session.user._id) return true;
            return false;
        },
    },
};
</script>
