<template>
    <v-form v-model="valid" ref="form" v-on:submit.prevent="noop" @keyup.native.enter="submit">
        <v-text-field label="E-mail" v-model="email" :rules="emailRules" required autofocus></v-text-field>
        <v-btn :disabled="!valid" v-show="btn_title" @click="submit">{{ btn_title }}</v-btn>
    </v-form>
</template>

<script>
export default {
    name: "form-for-email",
    props: ["btn_title"],
    data() {
        var default_email = "";
        if (ß.DEBUG) if (ß.MODE === "development") default_email = "admin@" + ß.HOSTNAME;
		if (ß.LOGIN_DEFAULT_EMAIL) default_email = ß.LOGIN_DEFAULT_EMAIL;
        return {
            valid: false,
            email: default_email,
            emailRules: [
                // fires all the time
                //  message-transition-enter-active message-transition-enter-to
                v => !!v || "...", //"E-mail is required",
                v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || ".." //"E-mail must be valid"
            ]
        };
    },
    methods: {
        submit() {
            //this.$refs.form.validate();
            this.$emit("return", this.email);
        },
        noop() {}
    }
};
</script>
