import Vue from "vue";

// initial state
const state = ß.EVENTHANDLER_ACTIONS;

// mutations
const mutations = {};

const getters = {
    // Access with $store.getters["database/getActionsComponent"]
    getActionsComponent: (state, getters) => (key) => {
        if (!key) return {};
        const element = ß.EVENTHANDLER_ACTIONS[key].format;
        if (!element) return {};
        return element;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};