<template>
    <v-card pa-10>
        <v-card-title>
            <div id="title" @click="load" class="px-4 pt-4 pb-3 text-h4">{{ selection.name }}</div>

            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="filter" single-line hide-details></v-text-field>
        </v-card-title>
        <v-card-subtitle>
            <p class="text-left">
                {{ selection.type }} collection <b>{{ selection.collection }}</b>
            </p>
        </v-card-subtitle>
        <div class="text-center pt-2" v-if="pageCount > 1">
            <v-pagination v-model="page" :length="pageCount" @input="load"></v-pagination>
        </div>
        <v-data-table
            :key="page"
            :items="getItems()"
            :items-per-page="itemsPerPage"
            :headers="headers"
            :search="search"
            hide-default-footer
            item-key="_id"            
            v-model="selected"
            class="elevation-1"
            dense
            :loading="loading"
            loading-text="##&en Loading... Please wait ##&hu Betöltés folyamatban ##"
        >
        </v-data-table>
        <div class="text-center pt-2" v-if="pageCount > 1 && items.length > 1">
            <v-pagination v-model="page" :length="pageCount" @input="load"></v-pagination>
        </div>
    </v-card>
</template>

<script>
import exchanger from "@/database-components/database-exchanger.js";
import DatabaseCsvDownload from "@/database-components/database-csv-download.vue";

import headersByFormat from "@/database-components/headersByFormat.js";
import filterBySearchterm from "@/database-components/filterBySearchterm.js";

export default {
    name: "datapoints-table",
    // collector is mandatory, collected is not
    props: ["selection"],
    data() {
        //const full_format = this.$store.getters["database/getDatabaseFormat"](this.collector.database);
        const format = {
            _timestamp: {
                is: "v-text-field",
                type: "Date",
            },
            name: {
                is: "v-text-field",
                type: "String",
            },
        };
        const headers = [
            {
                text: "Timestamp",
                align: "start",
                value: "_timestamp",
            },
        ].concat(
            this.selection.headers || [
                {
                    text: "Values",
                    value: "values",
                },
            ]
        );

        /*this.collector.datafields.forEach((datafield) => {
            format[datafield] = full_format[datafield];
        });*/
        return {
            //headers: headersByFormat(format),
            loading: true,
            headers: headers,
            search: "",
            dialog: false,
            document: null,
            selected: [],
            key: 0,
            items: [],
            //db: this.collector.database,
            page: 0,
            pageCount: 1,
            itemsPerPage: 1000,
            count: 0,
        };
    },
    created() {
        this.load(/*() => {
            this.page = this.pageCount;
        }*/);
    },
    methods: {
        load() {
            this.loading = true;

            const collection = this.selection.collection;
            const page = this.page;
            const itemsPerPage = this.itemsPerPage;
            this.items = [];
            this.$socket.client.emit("datapoints-read-collection", { collection, page, itemsPerPage }, ({ data, count, page }) => {
                this.count = count;
                this.pageCount = 1 + Math.floor(this.count / this.itemsPerPage);
                this.page = page;
                this.loading = false;

                if (this.selection.headers) {
                    this.items = data.map((e) => {
                        e._timestamp = ß.logic.getIdTimestampDate(e._id);
                        return e;
                    });
                    return;
                }
                // fallback for non-formatted case
                this.items = data.map((e) => {
                    const _timestamp = ß.logic.getIdTimestampDate(e._id);
                    delete e._id;
                    const values = JSON.stringify(e);
                    return { _timestamp, values };
                });
            });
        },
        getItems() {
            const ret = filterBySearchterm(this.items, this.search);
            return ret;
        },
    },
    components: { DatabaseCsvDownload },
};
</script>
<style scoped>
#title {
    cursor: pointer;
}
</style>
