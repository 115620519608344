<template>
    <section id="home" class="relative dark-bg">
        <v-container fill-height dark>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">##&en Location ##&hu Helyszín ##</th>
                            <th class="text-left">##&en In ##&hu be ##</th>
                            <th class="text-left">##&en Out ##&hu ki ##</th>
                            <th class="text-left">##&en Sum ##&hu Összeg ##</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>TOTAL</td>
                            <td>{{ $store.state.counter.total.be }}</td>
                            <td>{{ $store.state.counter.total.ki }}</td>
                            <td>{{ $store.state.counter.total.sum }}</td>
                        </tr>
                        <tr v-for="(data, location, i) in $store.state.counter.locations" :key="i">
                            <td>{{ location }}</td>
                            <td>{{ data.be }}</td>
                            <td>{{ data.ki }}</td>
                            <td>{{ data.sum }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "Table",
    route: { path: "/table", icon: "mdi-table-large", navigation: "table" },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#home {
    background-color: #333;
}
</style>
