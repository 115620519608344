// @DOC Create a date object with concrete values

// taken from boilerplate code date-and-time
function current_now(date) {
    if (!date) date = new Date();
    if (typeof date !== "object") date = new Date(date);

    var month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;

    var day = date.getDate();
    if (day < 10) day = "0" + day;

    var hour = date.getHours();
    if (hour < 10) hour = "0" + hour;

    var min = date.getMinutes();
    if (min < 10) min = "0" + min;

    var sec = date.getSeconds();
    if (sec < 10) sec = "0" + sec;

    return date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
}

function current_date(date) {
    if (!date) date = new Date();

    var month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;

    var day = date.getDate();
    if (day < 10) day = "0" + day;

    return date.getFullYear() + "-" + month + "-" + day;
}

export default function date(date) {
    if (!date) date = new Date();
    if (date instanceof Date === false) date = new Date(date);

    const o = {};

    o.getFullYear = date.getFullYear();
    o.getMonth = date.getMonth();
    o.getDate = date.getDate();
    o.getDay = date.getDay();
    o.getHours = date.getHours();
    o.getMinutes = date.getMinutes();
    o.getSeconds = date.getSeconds();
    o.getTime = date.getTime();

    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;

    let day = date.getDate();
    if (day < 10) day = "0" + day;

    let hour = date.getHours();
    if (hour < 10) hour = "0" + hour;

    let min = date.getMinutes();
    if (min < 10) min = "0" + min;

    let sec = date.getSeconds();
    if (sec < 10) sec = "0" + sec;

    o.timestamp = Math.floor(date.getTime() / 1000);
    o.now = date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
    o.date = date.getFullYear() + "-" + month + "-" + day;
    o.time = hour + ":" + min + ":" + sec;
    return o;
}
