<template>
    <div>
        <v-textarea v-model="text" @input="update" :label="label" :hint="hint"></v-textarea>
        <v-select v-model="tags" :items="taglist" chips :label="taglabel" multiple solo readonly flat></v-select>
    </div>
</template>

<script>
export default {
    name: "vuetiform-taggable-text",
    // taglist is an array of objects with text and value - similar to v-select
    // tag is the tagging prefix character, eg # or @
    props: ["value", "taglist", "label", "hint", "taglabel", "tag"],
    data: function () {
        return {
            text: this.value || this.default || '',
            tags: [],
        };
    },
    mounted() {
        this.checkText();
    },
    methods: {
        update() {
            this.checkText();
            this.$emit("input", this.text);
        },
        checkText() {
            const tag = this.tag || '#';
            const text = (this.text || '').toLowerCase();

            const results = {};

            const list = this.taglist.map((e) => ({ term: e.text.toLowerCase(), ...e }));

            text.split(tag)
                .slice(1)
                .forEach((part) => {
                    for (const elem of list) {
                        if (part.startsWith(elem.term)) results[elem.text] = elem.value;
                    }
                });
            this.tags = Object.values(results);
        },
    },
};
</script>
<style scoped></style>
