<template>
    <v-dialog v-model="dialog" @click:outside="close" min-width="400" max-width="800">
        <v-card class="pa-5 doc-card" :key="getKey()">
            <v-card-title>
                <h2 class="text-uppercase">{{ db }}</h2>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close"><v-icon> mdi-close </v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <div v-if="hasFormat()">
                    <p class="text-left" :title="getDetails()">{{ doc._id }} {{ doc._readonly ? "Readonly" : "" }}</p>
                    <vuetiform ref="vuetiform" v-model="doc" :format="getFormat()" :key="getKey()" />
                    <v-text-field
                        v-if="doc._id && !doc._readonly"
                        background-color="#efa"
                        v-model="comment"
                        append-outer-icon="mdi-send"
                        prepend-icon="mdi-comment-text-outline"
                        filled
                        clear-icon="mdi-close-circle"
                        clearable
                        outlined
                        label="Comment"
                        type="text"
                        @click:append-outer="sendComment"
                        autofocus
                        @keydown.enter.prevent="sendComment"
                    ></v-text-field>
                </div>
                <pre v-else> {{ doc }} </pre>
            </v-card-text>
            <v-card-actions class="dialog-actions">
                <v-btn :disabled="!editEnabled()" v-if="doc._id" class="mx-2" fab color="primary" @click="remove()"> <v-icon> mdi-delete </v-icon> </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mx-2" fab color="primary" @click="save()" :disabled="!editEnabled()"> <v-icon> mdi-database-check </v-icon> </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
// doc: {{doc}}
import Vuetiform from "@/vuetiform-components/vuetiform.vue";

export default {
    //
    // v-card @keydown.enter.prevent="save()" - removed, as autocomplete enter triggers closing
    //
    name: "database-document-dialog",
    // doc is passed as a prop, as an object
    // value is dialog open/closed
    props: ["db", "document", "value"],
    data: function () {
        return {
            key: 0,
            dialog: this.value, //false,
            doc: { ...this.document },
            comment: null,
        };
    },
    components: { Vuetiform },
    methods: {
        getKey() {
        	return '-' + this.key;
        },
        hasFormat() {
            return this.$store.getters["database/getDatabaseFormat"](this.db) !== undefined;
        },
        getFormat() {
            const format = this.$store.getters["database/getDatabaseFormat"](this.db);
            if (this.editEnabled()) return format;
            function get(f) {
                if (f === null) return f;
                if (typeof f !== "object") return f;
                if (Array.isArray(f)) return f;
                const c = {};
                for (const k in f) c[k] = get(f[k]);
                if (f.is) c.disabled = true;
                return c;
            }
            const clone = get(format);
            return clone;
        },
        save() {
            if (this.$refs.vuetiform.validate()) {
                let payload = {};
                // compose the payload
                payload.db = this.db;
                payload.op = "save"; //"create";
                payload.documents = [this.doc];

                // send the event and close the dialog once the answer is ack
                this.$socket.client.emit("database-operation", payload, (result) => {
                    this.close();
                    if (result.error) alert(result.error);
                });
                return;
            }
        },
        remove() {
            let payload = {};
            payload.db = this.db;
            payload.op = "remove";
            payload.documents = [this.doc];

            this.$socket.client.emit("database-operation", payload, (result) => {
                this.close();
                if (result.error) alert(result.error);
            });
        },
        sendComment() {
            let payload = {};
            payload.db = this.db;
            payload._id = this.doc._id;
            payload.comment = this.comment;

            this.$socket.client.emit("database-comment", payload, (result) => {
                if (result.error) alert(result.error);
                this.comment = null;
                this.key++;
                this.doc = { ...this.$store.state.database[this.db].data[this.doc._id] };
            });
        },
        close() {
            this.key++;
            this.dialog = false;
            this.$emit("input", false);
        },
        getDetails() {
            return ("# " + new Date(this.doc.createdAt).toLocaleString() || "unknown") + "\n" + ("@ " + new Date(this.doc.updatedAt).toLocaleString() || "unknown");
        },
        editEnabled() {
            if (this.doc._readonly === true) return false;
            const db = this.db;
            return this.$store.getters["database/getDatabaseFullAccess"](db);
        },
    },
    watch: {
        value: function () {
            this.key++;
            this.dialog = this.value;
        },
    },
};
</script>

<style scoped>
pre {
    text-align: left;
    white-space: pre-line;
}
.doc-card {
    padding-top: 20px;
}
.dialog-actions {
    padding: 20px;
}
</style>
