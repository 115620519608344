import Vue from "vue";

const state = {
	routes: []
};

const mutations = {
    SOCKET_UPDATE_NAVIGATION(state, payload) {
        Vue.set(state, "routes", payload);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
