<template>
    <v-list nav dense>
        <v-list-item-group active-class="primary--text">
            <v-list-item v-for="r in routes()" @click="open(r)" :key="r">
                <v-icon>mdi-memory</v-icon>
                <v-list-item-title> {{ r }}.log</v-list-item-title>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import * as axios from "__axios";

// A simple language selector button for two languages

export default {
    name: "checkpoints-routes",
    data: () => ({
        //app_languages: Object.keys(ß.APP_LANGUAGES)
    }),
    props: {
        label: {
            type: String,
            default: "##&en Magyarul ##&hu English ##",
        },
    },
    methods: {
        open(a) {
            window.open("https://" + ß.HOSTNAME + "/checkpoints/" + a + ".log", "_blank");
        },
        routes() {
            const checkpoints = this.$store.state.checkpoints.checkpoints;
            return Object.keys(checkpoints);
        },
    },
};
</script>
