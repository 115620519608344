<template>
    <v-card-text>
        <v-container grid-list-md>
            <transition name="component-fade" mode="out-in"> <component :is="selected" @return="action" v-bind:btn_title="get_btn_title()" /> </transition>
        </v-container>
        <v-container>
            <v-alert v-if="msg !== true && msg !== false" id="alert" v-show="msg" :value="true" :type="type" transition="fade" class="text-sm-left">{{ msg }}</v-alert>
            <v-progress-circular v-show="progress" :size="50" color="primary" indeterminate></v-progress-circular>
        </v-container>
    </v-card-text>
</template>

<script>
import * as axios from "__axios";

import form_for_email from "@/login-components/FormForEmail.vue";
import form_for_name from "@/login-components/FormForName.vue";

export default {
    name: "login-card-email-login",
    data() {
        return {
            email: null,
            name: null,
            registered: false,
            progress: false,
            msg: false,
            type: "info",
            selected: "form_for_email",
        };
    },
    components: {
        form_for_email,
        form_for_name,
    },
    methods: {
        get_btn_title() {
            if (this.selected === "form_for_name") return "##&en Register ##&hu Regisztráció ##";
            return "##&en Submit ##&hu Küldés ##";
        },
        action(arg) {
            //this.$refs.form.validate();
            if (this.selected === "form_for_email") this.email = arg;
            if (this.selected === "form_for_name") this.name = arg;
            this.progress = true;
            this.msg = false;
            this.type = "info";
            this.post_email_request();
        },
        post_email_request(email) {
            this.type = "info";
            axios({
                method: "post",
                url: "/post-email-request.json",
                data: { email: this.email, name: this.name },
            })
                .then((response) => {
                    this.progress = false;

                    console.log(response.data);
                    if (response.data === "NAME") {
                        this.selected = "form_for_name";
                        this.progress = false;
                        return;
                    }
                    if (response.data === "OK") {
                        this.msg = "##&en Please check your mailbox ##&hu Ellenőrizze póstafiókját ##";
                        this.selected = null;
                        return;
                    }
                    this.type = "info";
                    this.msg = response.data;
                })
                .catch((error) => {
                    this.progress = false;
                    this.type = "error";
                    this.msg = "##&en Network error. ##&hu Hálózati hiba ##";
                    // eslint-disable-next-line
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
#alert {
    opacity: 0.6;
}
</style>
