<template>
        <v-form ref="form" lazy-validation :key="database + '-' + datafield">
            <vuetiform-element v-model="model" :format="getFormat()" @input="updateModel" />
        </v-form>
</template>

<script>
//import VuetiformElementDatafield from "@/vuetiform-components/vuetiform-element.vue";

export default {
    // note that value is not really processed for now.

    name: "vuetiform-database-datafield",
    components: { VuetiformElement: () => import("@/vuetiform-components/vuetiform-element.vue") },

    props: ["value", "database", "datafield"],
    data: function () {
        return {
            model: { ...this.value },
        };
    },
    methods: {
        getFormat() {
            const key = this.database + '.' + this.datafield;
            const format = this.$store.getters["database/getDatabaseDatafieldFormat"](this.database, this.datafield);
            //console.log(format);
            let o = {};
            o[key] = format;
            return o;
        },
        // this will make sure we have two-way data binding
        updateModel() {
            const key = this.database + '.' + this.datafield;
            let model = { };
            model[key] = this.model[key];
            this.$emit("input", model);
        },
        reset() {
            return this.$refs.form.reset();
        },
        resetValidation() {
            return this.$refs.form.resetValidation();
        },
        validate() {
            return this.$refs.form.validate();
        },
    },
};
</script>
<style scoped>
#label {
    margin-bottom: 0;
}
</style>
