import Vue from "vue";

// for resetState
const getDefaultState = () => {
    return {};
};

// initial state
const state = {};

// mutations
const mutations = {
    SOCKET_DATABASE_UPDATE_DATABASE(state, payload) {
        for (const db in payload) {
            console.info("[database] update database: " + db, Object.keys(payload[db].data).length, "documents", payload);
            Vue.set(state, db, payload[db]);
        }
    },
    /*
    SOCKET_DATABASE_UPDATE_DOCUMENT(state, payload) {
        // deprecated, ...
        for (const db in payload) {
            if (!state[db]) return console.log("[database] database don't exists:", db);

            const o = {};
            o.data = state[db].data;
            if (payload[db].format) o.format = payload[db].format;
            //o.module = payload[db].module;
            if (payload[db].data) o.data = { ...state[db].data, ...payload[db].data };
            //
            if (payload[db].rights) o.rights = payload[db].rights;
            //for (const e of payload[db].data) o.data[e._id] = e;
            console.log("[database] update documents: " + db, Object.keys(payload[db].data).length, "documents", payload);
            Vue.set(state, db, o);
        }
    },*/
};

const getters = {
    // get the format schema of a database
    // $store.getters['database/getDatabaseFormat'](db)
    getDatabaseFormat: (state) => (db) => {
        if (db === undefined) return null;
        if (state[db] === undefined) return null;
        if (state[db].format === undefined) return null;
        const format = { ...state[db].format };
        // make sure the comment is the last field.
        if (format.comment) {
            const comment = format.comment;
            delete format.comment;
            format.comment = comment;
        }
        // ref is not allowed as attribute, so we need a workaround
        /*for (const key in format)  if (format[key]) if (format[key].ref) {
            if (format[key].reference === undefined) format[key].reference = format[key].ref;
            if (format[key].uri === undefined) format[key].uri = '/database/' + format[key].ref;
        }*/
        return format;
    },
    // Access with $store.getters['database/getDatabaseList']
    // returns an array of database names
    getDatabaseList: (state, getters) => {
        return Object.keys(state);
    },
    // /@vue-modules/database/vue/database-components/database-card.vue?line=84
    // returns an array or null
    getDatabaseArrayData: (state) => (db) => {
        if (db === undefined) return null;
        if (state[db] === undefined) return null;
        return Object.values(state[db].data);
    },
    getDatabaseDocumentsNameArray: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        return Object.values(state[db].data).map((e) => e.name);
    },

    getDatabaseDocumentsIdArray: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        return Object.keys(state[db].data);
    },
    // /@vue-modules/databases/run/define.mjs
    // returns an array of text/value objects for vuetify
    getDatabaseDocumentsNameIdArray: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        return Object.values(state[db].data).map((e) => {
            return { text: e.name, value: e._id };
        });
    },
    // universal
    getDatabaseDocumentsNameDbIdArray: (state) => (...dbs) => {
        return [].concat(...Object.values(dbs).map((db) => {
            if (db === undefined) return [];
            if (state[db] === undefined) return [];
            return Object.values(state[db].data).map((e) => {
                return { text: e.name, db, value: e._id };
            });
        }));
    },
    getDatabaseDocumentsFirstElement: (state) => (db) => {
        if (db === undefined) return {};
        if (state[db] === undefined) return {};
        if (state[db].data.length < 1) return {};
        const e = Object.values(state[db].data)[0];
        return { text: e.name, value: e._id };
    },
    // getDatabaseDocumentsNameIdArray with a filter applied, for selecting a set of documents by a value
    getDatabaseDocumentsNameIdArrayFilterByFieldValue: (state) => (db, field, value) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        return Object.values(state[db].data)
            .filter((e) => e[field] === value)
            .map((e) => {
                return { text: e.name, value: e._id };
            });
    },
    // used in query_filters .. TODO make it more generic
    getDatabaseDocumentsNameIdArrayDualFilterByFieldValue: (state) => (db, field1, value1, field2, value2) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        return Object.values(state[db].data)
            .filter((e) => e[field1] === value1)
            .filter((e) => e[field2] === value2)
            .map((e) => {
                return { text: e.name, value: e._id };
            });
    },
    //
    getDatabaseDatafields: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        // format must exists now
        return Object.keys(state[db].format);
    },
    getDatabaseDatafieldFormat: (state) => (db, field) => {
        if (db === undefined) return {};
        if (state[db] === undefined) return {};
        // format must exists now
        return state[db].format[field];
    },
    // @vue-modules/databases/datafields/4110.DATABASES_FIELD_COMPONENTS.database-document-indirect-reference.mjs?line=14
    getDatabaseRefDatafields: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        // format must exists now
        return Object.keys(state[db].format).filter((e) => state[db].format[e].ref !== undefined);
    },
    getDatabaseRefDatabases: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        // format must exists now
        return Object.keys(state[db].format)
            .filter((e) => state[db].format[e].ref !== undefined)
            .map((e) => state[db].format[e].ref);
    },
    // get fields
    getDatabaseResolvingDatafields: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        const results = [];

        const date_keys = Object.keys(ß.logic.objectifyDate());

        // recursive function
        function getFields(_db, path, depth) {
            // stop conditions
            if (depth > 10) return console.log("[database] getDatabaseResolvingDatafields depth limit reached on " + db);
            if (depth > 0) if (db === _db) return;
            // mongoose fields
            results.push(path + "_id");
            results.push(path + "createdAt");
            results.push(path + "updatedAt");

            // single fields
            Object.keys(state[_db].format).forEach((field) => {
                const def = state[_db].format[field];
                if (!def) return; // console.log("no def",_db,field);
                if (def.multiple) return;

                const ref = def.ref;
                if (!ref) return results.push(path + field);
                getFields(ref, path + field + ".", depth + 1);
            });
        }

        getFields(db, "", 0);

        return results.map((e) => "$" + e);
    },
    // get database references
    getDatabaseResolvingDatafieldReferences: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        const results = [];

        // recursive function
        function getFields(_db, path, depth) {
            // stop conditions
            if (depth > 10) return console.log("[database] getDatabaseResolvingDatafields depth limit reached on " + db);
            if (depth > 0) if (db === _db) return;

            // single fields
            Object.keys(state[_db].format).forEach((field) => {
                const def = state[_db].format[field];
                if (!def) return;
                if (def.multiple) return;

                const ref = def.ref;
                if (!ref) return;
                results.push(path + field);
                getFields(ref, path + field + ".", depth + 1);
            });
        }

        getFields(db, "", 0);

        return results;
    },

    hasDatabaseDatafield: (state) => (db, field) => {
        if (!field) return "-";
        if (state[db]) if (state[db].format) if (state[db].format[field]) return true;
        return false;
    },
    // @vue-modules/databases/datafields/4110.DATABASES_FIELD_COMPONENTS.database-document-indirect-reference.mjs?line=22
    getDatafieldRef: (state) => (field) => {
        if (!field) return "-";
        let ref = null;
        for (const dbx in state)
            if (state[dbx].format) if (state[dbx].format[field] !== undefined) if (state[dbx].format[field].ref !== undefined) ref = state[dbx].format[field].ref;

        return ref;
    },

    // database document indirect value
    // @vue-modules/databases/datafields/4110.DATABASES_FIELD_COMPONENTS.database-document-indirect-reference.mjs?line=28
    getDatabaseIndirectRefDatafields: (state) => (field) => {
        if (!field) return [];

        let ref = null;
        for (const dbx in state)
            if (state[dbx].format) if (state[dbx].format[field] !== undefined) if (state[dbx].format[field].ref !== undefined) ref = state[dbx].format[field].ref;

        if (!ref) {
            console.log(ref, "No DB ref");
            return [];
        }
        if (state[ref] === undefined) {
            console.log(ref, "No state");
            return [];
        }
        // format must exists now
        const ret = Object.keys(state[ref].format)
            .filter((e) => state[ref].format[e].ref !== undefined)
            .filter((e) => state[ref].format[e].multiple === true);
        //console.log(field, ref, ret, state[ref].format);
        return ret;
    },
    // @vue-modules/databases/run/define.mjs
    getDatabaseDocumentDatafieldValuesAsNameIdArray: (state) => (db, doc, field) => {
        if (!db) return [];
        if (!doc) return [];
        if (!field) return [];

        let ref = null;
        for (const dbx in state)
            if (state[dbx].format) if (state[dbx].format[field] !== undefined) if (state[dbx].format[field].ref !== undefined) ref = state[dbx].format[field].ref;

        if (!ref) return [];
        if (state[db] === undefined) return [];
        if (state[ref] === undefined) return [];

        if (!state[db].data[doc]) return [];
        if (!state[db].data[doc][field]) return [];

        return state[db].data[doc][field]
            .filter((e) => state[ref].data[e] !== undefined)
            .map((e) => {
                return { text: state[ref].data[e].name, value: e };
            });
    },

    // a property of databases
    getDatabaseFullAccess: (state) => (db) => {
        if (db === undefined) return null;
        if (state[db] === undefined) return null;
        return state[db].fullaccess || false;
    },

    // experimental for fox
    getDatabaseDocumentDatafieldPredefinedValuesArray: (state) => (db, doc, field) => {
        if (db === undefined) return ['no db argument'];
        if (state[db] === undefined) return ['no database'];
        if (state[db].data[doc] === undefined) return ['no document'];
        if (state[db].data[doc][field] === undefined) return ['no field'];
        // format must exists now
        return state[db].data[doc][field].split('|');//.map(e => e.trim().toLowerCase());
    },
    
    /*
    // unused
    getDatafieldList: (state, getters) => {
        const list = [];
        for (const db in state) for (const field in state[db].format) list.push(field);
        return list;
    },
    // unused
    getDatabaseDatafields: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        // format must exists now
        return Object.keys(state[db].format);
    },
    
    getDatabaseDatafieldsWithSingleValue: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        // format must exists now
        return Object.keys(state[db].format).filter(e => state[db].format[e] && state[db].format[e].multiple !== true);
    },    
    
    getDatabaseDatafieldsWithMultiple: (state) => (db) => {
        if (db === undefined) return [];
        if (state[db] === undefined) return [];
        // format must exists now
        console.log(Object.keys(state[db].format));
        return Object.keys(state[db].format).filter(e => state[db].format[e] && state[db].format[e].multiple === true);
    },
    
    getDatabaseDocumentById: (state) => (db, id) => {
        if (db === undefined) return null;
        if (id === undefined) return null;
        if (state[db] === undefined) return null;
        return { ...state[db].data.filter((e) => e._id === id)[0] };
    },*/

    // these are more database-document related operations
    getDocumentById: (state) => (db, id) => {
        if (db === undefined) return null;
        if (state[db] === undefined) return null;
        if (id === undefined) return null;
        return state[db].data[id];
    },
    // assume name is unique in each database
    getDocumentByName: (state) => (db, name) => {
        if (db === undefined) return null;
        if (state[db] === undefined) return null;
        if (name === undefined) return null;
        // format must exists now
        const a = Object.values(state[db].data).filter((e) => e.name === name);
        if (a.length > 0) return a[0];
        return null;
    },
    hasDatabase: (state) => (db) => {
        if (db === undefined) return false;
        if (state[db] !== undefined) return true;
        return false;
    },
    getReferencedDocumentsProperty: (state) => (db, arr, prop) => {
        if (db === undefined) return null;
        if (state[db] === undefined) return null;
        if (arr === undefined) return null;
        if (prop === undefined) return null;

        return arr.map((e) => state[db].data[e][prop]);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
