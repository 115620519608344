<template>
    <v-card-text>
        <v-container grid-list-md>
            <transition name="component-fade" mode="out-in"> <component :is="selected" @return="action" v-bind:btn_title="get_btn_title()" /> </transition>
        </v-container>

        <v-card id="remcard" grid-list-md @click="dontRem()" style="margin: 12px" flat>
            <v-alert dense v-show="rem" :value="true" icon="mdi-cookie-clock" outlined id="remember" color="#6666" transition="fade" class="text-sm-left">
                ##&en Permanent login ##&hu Permanens login ##
            </v-alert>
        </v-card>
        <v-container>
            <v-alert id="alert"  v-show="msg" :value="true" :type="type" transition="fade" class="text-sm-left">{{ msg }}</v-alert>
            <v-progress-circular v-show="progress" :size="50" color="primary" indeterminate></v-progress-circular>
        </v-container>
    </v-card-text>
</template>

<script>
import * as axios from "__axios";

import form_for_email from "@/login-components/FormForEmail.vue";
import form_for_password from "@/login-components/FormForPassword.vue";
import form_for_name from "@/login-components/FormForName.vue";

export default {
    name: "login-card-password-login",
    data() {
        return {
            email: null,
            registered: false,
            rememberme: true,
            selected: "form_for_email",
            progress: false,
            msg: false,
            type: "info",
            name: null,
            rem: true,
        };
    },
    components: {
        form_for_email,
        form_for_password,
        form_for_name,
    },
    methods: {
        dontRem() {
            this.rem = false;
        },
        get_btn_title() {
            if (this.selected === "form_for_password")
                if (this.registered) return "##&en LOGIN ##&hu Bejelentkezés ##";
                else return "##&en REGISTER ##&hu Regisztráció ##";
            return "##&en NEXT ##&hu Tovább ##";
        },
        action(arg) {
            //this.$refs.form.validate();
            this.progress = true;
            this.msg = false;
            if (this.selected === "form_for_email") return this.post_email(arg);
            if (this.selected === "form_for_password") return this.post_login(arg);
            if (this.selected === "form_for_name") {
                this.selected = "form_for_password";
                this.progress = false;
                this.name = arg;
            }
        },
        post_email(email) {
            this.type = "info";
            axios({
                method: "post",
                url: "/post-email.json",
                data: { email: email },
            })
                .then((response) => {
                    this.progress = false;

                    if (response.data === "USER") {
                        this.registered = true;
                        this.selected = "form_for_password";
                        this.progress = false;
                        this.email = email;
                        return;
                    }
                    if (response.data === "GOODFORMAT") {
                        //this.selected = "form_for_password";
                        this.selected = "form_for_name";
                        this.progress = false;
                        this.email = email;
                        return;
                    }
                    if (response.data === "OK") {
                        // user logged in
                        this.$store.dispatch("server/load_session");
                        this.$emit("dialog_handler", "selector");
                        return;
                    }
                    this.type = "error";
                    this.msg = response.data;
                })
                .catch((error) => {
                    this.progress = false;
                    this.type = "error";
                    this.msg = "##&en Network error. ##&hu Hálózati hiba ##";
                    // eslint-disable-next-line
                    console.log(error);
                });
        },
        post_login(password) {
            this.type = "info";
            axios({
                method: "post",
                url: "/post-login.json",
                data: { email: this.email, password: password, rem: this.rem, name: this.name },
            })
                .then((response) => {
                    this.progress = false;
                    if (response.data === "OK") {
                        // user logged in
                        this.$store.dispatch("server/load_session");
                        this.$emit("dialog_handler", "selector");
                        return;
                    }
                    if (response.data === "NO") this.msg = "##&en Sorry. Wrong password. ##&hu Sajnos ez nem a megfelelő jelszó ##";
                    else {
                        this.msg = response.data;
                        this.type = "error";
                    }
                    this.progress = false;
                })
                .catch((error) => {
                    this.progress = false;
                    this.type = "error";
                    this.msg = "##&en Network error. ##&hu Hálózati hiba ##";
                    // eslint-disable-next-line
                    console.log(error);
                });
        },
    },
    computed: {
        is() {
            if (this.$store.state.server.session) if (this.$store.state.server.session.user) if (this.$store.state.server.session.user._id) return "user";
            return "guest";
        },
        is_user() {
            if (this.$store.state.server.session) if (this.$store.state.server.session.user) if (this.$store.state.server.session.user._id) return true;
            return false;
        },
    },
};
</script>
<style scoped>
    .remcard {
    	margin: 20;
    }
    </style>
