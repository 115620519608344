<template>
    <div v-if="getItems().length > 0">
        {{ selection }}
        <div v-for="(i, ix) in getList()">
            <p id="label" class="font-weight-thin text-left">{{ getLabel(ix) }}</p>
            <v-checkbox
                v-for="(c, cx) in getItems()"
                v-model="selection"
                :key="cx"
                @click="click(c)"
                :label="stringFormat(c)"
                :value="getValue(i, c)"
                :disabled="readonly"
                hide-details
            />
            <br />
        </div>
    </div>
</template>

<script>
export default {
    // note that value is not really processed for now.

    name: "vuetiform-checkboxes",
    props: ["value", "label", "items", "multiple", "readonly", "action", "list"],
    data: function () {
        let selection = [];
        if (this.value) if (Array.isArray(this.value)) this.value.forEach((e) => selection.push(e));
        return {
            selection,
        };
    },
    methods: {
        update() {
            this.$emit("input", this.selection);
        },
        getItems() {
            // not sure about this here
            if (this.items) return this.items;
            return [];
        },
        getList() {
            if (this.list) return this.list;
            return [this.label];
        },
        getLabel(x) {
            if (this.list) return this.list[x];
            return this.label;
        },
        getValue(i, c) {
            if (this.list) return i + "|" + c;
            return c;
        },
        stringFormat(i) {
            if (typeof i === "object") if (i.text !== undefined) return i.text;
            return i;
        },
        click(e) {
            if (typeof this.action === "function") this.action(e);

            if (this.readonly) return;
            this.$emit("input", this.selection);
        },
    },
};
</script>
<style scoped>
#label {
    margin-bottom: 0;
}
</style>
