import Vue from "vue";

// for resetState
const getDefaultState = () => {
    return {};
};

// initial state
const state = {};

// mutations
const mutations = {};

const getters = {
    getFoxNavigationContextKeysByType: (state, getters, rootState, rootGetters) => (fox_navigation, type) => {
        const document = rootState.database.fox_navigation.data[fox_navigation];
        if (!document) return [];
        if (!document.context_keys) return [];
        return document.context_keys
            .filter((context_key) => {
                const doc = rootState.database.context_keys.data[context_key];
                if (!doc.type) return false;
                if (doc.type === type) return true;
                return false;
            })
            .map((context_key) => {
                const doc = rootState.database.context_keys.data[context_key];
                return { text: doc.name, value: context_key };
            });
    },
    getFoxNavigationContextKeysByDatabase: (state, getters, rootState, rootGetters) => (fox_navigation, db) => {
        const document = rootState.database.fox_navigation.data[fox_navigation];
        if (!document) return [];
        if (!document.context_keys) return [];
        return document.context_keys
            .filter((context_key) => {
                const doc = rootState.database.context_keys.data[context_key];
                if (!doc.type) return false;
                if (doc.type === "document:" + db) return true;
                return false;
            })
            .map((context_key) => {
                const doc = rootState.database.context_keys.data[context_key];
                return { text: doc.name, value: context_key };
            });
    },
    getContextValueTypes: (state, getters, rootState, rootGetters) => (type) => {
        const types = [
            { text: "String", value: "string" },
            { text: "Number", value: "number" },
            { text: "Boolean", value: "boolean" },
            { text: "Object ID", value: "objectid" },
            { text: "Document", value: "document" },
        ];

        Object.keys(rootState.database).forEach((db) => types.push({ text: db + " document", value: "document:" + db }));

        return types;
    },
    getContextKeys: (state, getters, rootState, rootGetters) => (type) => {
        const context_keys = Object.values(rootState.database.context_keys.data).filter((e) => e.type === type);
        return context_keys.map((e) => e.name);
    },
    getActionContextKeys: (state, getters, rootState, rootGetters) => (action) => {
        if (!action._parent) return [{ text: "no _parent eventhandler selected", value: null }];
        if (!rootState.database.eventhandlers.data[action._parent])
            return [{ text: "invalid _parent eventhandler selected", value: null }];
        const context_keys = rootState.database.eventhandlers.data[action._parent].context_keys.map((value) => {
            const document = rootState.database.context_keys.data[value];
            const text = document.name;
            return text;
        });
        return context_keys;
    },

    getActionContextKeysForDatabaseDocument: (state, getters, rootState, rootGetters) => (action, db) => {
        if (!db) db = action.database;
        if (!action._parent) return [{ text: "no _parent eventhandler selected", value: null }];
        if (!rootState.database.eventhandlers.data[action._parent])
            return [{ text: "invalid _parent eventhandler selected", value: null }];
        
        const context_keys = rootState.database.eventhandlers.data[action._parent].context_keys.filter(id => {
        	const document = rootState.database.context_keys.data[id];
            return document.type === "document:" + db;
        }).map((id) => {
            const document = rootState.database.context_keys.data[id];
            const text = document.name;
            return text;
        });
        
        return context_keys;
    },

    getActionContextFields: (state, getters, rootState, rootGetters) => (action) => {
        const dates = [
            "$date.getFullYear",
            "$date.getMonth",
            "$date.getDate",
            "$date.getDay",
            "$date.getHours",
            "$date.getMinutes",
            "$date.getSeconds",
            "$date.getTime",
            "$date.timestamp",
            "$date.now",
            "$date.date",
            "$date.time",
        ];

        let initiator_fields = [];
        if (!action) return [{ text: "no action argument", value: null }];

        if (!action._parent) return [{ text: "no _parent eventhandler selected", value: null }];
        if (!rootState.database.eventhandlers.data[action._parent])
            return [{ text: "invalid _parent eventhandler selected", value: null }];

        const eventhandler = rootState.database.eventhandlers.data[action._parent];

        if (eventhandler.initiator === "timer") {
            initiator_fields = ["$timer.name", "$timer.type"];
            // add timer types fields? ... console.log(ß.TIMER_TYPES.cyclic.format);
        }

        if (eventhandler.initiator === "regulation") {
            initiator_fields = [
                "$regulation.name",
                "$regulation.op",
                "$regulation.database",
                "$regulation.document",
                "$regulation.document.name",
                "$regulation.document._id",
                "$user.name",
            ];
        }

        if (eventhandler.initiator === "fox") {
            initiator_fields = ["$session"];
        }
        
        if (eventhandler.initiator === "detector") {
            initiator_fields = ["$detector.name", "$detector.sensor.name"];
            // add timer types fields? ... console.log(ß.TIMER_TYPES.cyclic.format);
        }
        
        let eventhandler_fields = ["$eventhandler.name"];
        // Add all eventhandler fields?

        const fields = [];

        (eventhandler.context_keys || []).forEach((e) => {
            const context_key = rootState.database.context_keys.data[e];

            if (!context_key) return fields.push("[ invalid context_key " + e + " ]");
            if (["string", "number", "boolean"].includes(context_key.type)) return fields.push("$" + context_key.name);
            if (["objectid"].includes(context_key.type)) return fields.push("$" + context_key.name);
            if (["document"].includes(context_key.type)) return fields.push("$" + context_key.name + ".name");

            if (context_key.type.startsWith("document:")) {
                const db = context_key.type.split(":")[1];
                const list = rootGetters["database/getDatabaseResolvingDatafields"](db).map((e) => {
                    return "$" + context_key.name + "." + e.split("$")[1];
                });
                fields.push(...list);
            }
        });

        //console.log(_parent.initiator, action, rootState.database);
        return [...initiator_fields, ...eventhandler_fields, ...fields, ...dates];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
