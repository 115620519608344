<template>
    <v-card pa-10>
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="filter" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="getItems()"
            :search="search"
            :footer-props="{
                'items-per-page-options': [25, 50, 100, 500, -1],
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
            }"
            item-key="_id"
            show-select
            v-model="selected"
            class="elevation-1"
            dense
            @click:row="open"
        >
        </v-data-table>

        <v-card-actions>
            <database-csv-upload :db="db" :key="db + '-csv-upload'" v-if="addEnabled()" />
            <database-csv-download :db="db" :key="db + '-csv-download'" :items="selected" />
            <v-btn class="mx-2 ma-10" fab color="primary" @click="remove()" :disabled="selected.length < 1"> <v-icon> mdi-delete </v-icon> </v-btn>
            <v-spacer> </v-spacer>
            <v-btn class="mx-2" fab dark color="primary" @click="open" v-if="addEnabled()"> <v-icon> mdi-plus </v-icon></v-btn>
        </v-card-actions>

        <database-document-dialog :db="db" :document="document" v-model="dialog" :key="key" />
    </v-card>
</template>

<script>
// disable-pagination hide-default-footer

import DatabaseDocumentDialog from "@/database-components/database-document-dialog.vue";
import exchanger from "@/database-components/database-exchanger.js";
import DatabaseCsvUpload from "@/database-components/database-csv-upload.vue";
import DatabaseCsvDownload from "@/database-components/database-csv-download.vue";

import headersByFormat from "@/database-components/headersByFormat.js";
import filterBySearchterm from "@/database-components/filterBySearchterm.js";

export default {
    name: "database-table",
    props: ["db", "items"],
    data() {
        const format = this.$store.getters["database/getDatabaseFormat"](this.db);
               return {
            headers: headersByFormat(format),
            search: "",
            dialog: false,
            document: null,
            selected: [],
            key: 0,
        };
    },
    methods: {
        remove() {
            let _this = this;
            let payload = {};
            payload.db = _this.db;
            payload.op = "remove";
            payload.documents = this.selected;
            this.$socket.client.emit("database-operation", payload, function (result) {
                if (result.error) alert(result.error);
            });
        },
        // this is the exchanger for vuetiform styled database format entries
        getItems() {
            
            return filterBySearchterm(exchanger(this.items, this, true), this.search);
        },
        open(t, i) {
            //console.log("Database table open:", t, i, this.items[i.index].name);
            const id = t._id;
            if (id) this.document = this.$store.state.database[this.db].data[id];
            else this.document = {};
            this.key++;
            this.dialog = true;
        },
        addEnabled() {
            const db = this.db;
            const dba = this.$store.state.server.session.databases;
            if (!dba) return true;
            if (!dba[db]) return true;
            if (dba[db].modify) return true;

            return false;
        },
    },
    components: { DatabaseDocumentDialog, DatabaseCsvUpload, DatabaseCsvDownload },
};
</script>
