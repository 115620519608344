<template>
    <div>
        <v-btn class="mx-2" fab color="primary" :loading="isSelecting" @click="onButtonClick"><v-icon> mdi-upload </v-icon></v-btn>
        <input ref="uploader" class="d-none" type="file" accept=".csv" @change="onFileChanged" />

        <v-dialog v-model="dialog" scrollable>
            <v-card>
                <v-card-title>
                    <h2 class="text-uppercase database-upload-title">CSV Import {{ items.length }} {{ db }}</h2>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false"><v-icon> mdi-close </v-icon></v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <database-csv-upload-table :db="db" v-model="items" />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <pre class="text-left">{{ messages }}</pre>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" fab color="primary" @click="sendData()"> <v-icon> mdi-database-check </v-icon> </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import * as axios from "__axios";


import DatabaseCsvUploadTable from "@/database-components/database-csv-upload-table.vue";
import exchanger from "@/database-components/database-exchanger.js";

import * as CSV from "csv-string";

export default {
    name: "database-csv-upload",
    props: ["db"],
    data() {
        return {
            selectedFile: null,
            isSelecting: false,
            dialog: false,
            items: [],
            messages: "",
        };
    },
    components: { DatabaseCsvUploadTable },
    methods: {
        onButtonClick() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );

            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            let _this = this;
            this.selectedFile = e.target.files[0];

            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => _this.processData(e.target.result);
            reader.readAsText(file);
        },
        processData(data) {
            this.messages = "";
            // data will be an array
            const arr = CSV.parse(data);

            // the vuetiform-style
            const format = this.$store.getters["database/getDatabaseFormat"](this.db);

            // an array of properties, where nested objects have the dot-notation
            // const header = headersByFormat(format).map((e) => e.value);

            // the fields are taken from the first line
            const fields = arr[0];

            // we will need to access the vuetiform-style definitions, with the path in dot notation in fields
            // it will be an array with the objects under the column indexes.
            const head = fields.map((field) => {
                // to find them we start with the format
                let s = format;
                // the field is in a dot notation
                // and move our word pointer
                for (const w of field.split("."))
                    if (s[w]) s = s[w];
                    else return {};
                // if not found an empty object is returned, if found the definition object
                return s;
            });

            // our results will be a unsorted array of objects
            let items = [];
            // convert the array line-by line skipping the headers
            for (let i = 1; i < arr.length; i++) {
                // the array will contain objects
                let item = {};
                // a line
                let a = arr[i];
                // for each field
                for (let j = 0; j < a.length; j++) {
                    // the filed is split to a field-array, resolving the dot notation
                    let fa = fields[j].split(".");
                    // the fields's last part will contain the value
                    let fl = fa.pop();
                    // the value is in the line, called a[j]
                    let value = a[j];
                    // it might be an empty string, then skip it
                    if (a[j] === "") continue;
                    // we need a pointer
                    let p = item;
                    // so for each word in the path
                    for (const w of fa) {
                        // create the object in the nested object
                        if (!p[w]) p[w] = {};
                        // and move the pointer to that array
                        p = p[w];
                    }
                    // We need an additional check on the database format, to find multiple values.
                    let o = head[j];
                    // we will unify value and values into values array.
                    let values = [];
                    // if the format has the multiple set, it is an array
                    if (o.multiple) values = value.split(",");
                    else values[0] = value;
                    // validate values
                    if (o.validators) {
                        let validation = validate(values, o.validators);
                        if (validation !== true) {
                            this.messages += "##&en line: ##" + i + "##&hu . sor ## " + fields[j] + " ##&en validation failed. ##&hu Érvényesítési hiba ## " + validation + "\n";
                            values = [];
                        }
                    }
                    // once the pointer is in final position, set the value
                    if (o.multiple) p[fl] = values;
                    else p[fl] = values[0];
                }
                // we have the object representing a line
                items.push(item);
            }

            this.items = items;
            this.dialog = true;
        },
        sendData() {
            let _this = this;

            let payload = {};
            payload.db = this.db;
            payload.op = "save";
            payload.documents = exchanger(this.items, this, false);

            this.$socket.client.emit("database-operation", payload, function ({ messages }) {
                if (messages) if (messages.length > 0) {
                    _this.messages += "\n\n" + messages;
                	return;
                }
                _this.dialog = false;
            });
        },
    },
};

function validate(values, validators) {
    //Ł(values, validators);
    for (const v of validators.split("|")) {
        for (const e of values)
            if (!ß.logic[v]) console.log("database-csv-upload validate: cannot use validator:" + v, e);
            else if (ß.logic[v](e) !== true) return '"' + e + '" ' + ß.logic[v](e);
    }
    return true;
}
</script>
