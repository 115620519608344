import Vue from "vue";
// initial state
const state = {
    locations: {},
    total: {},
};

// mutations
const mutations = {
    SOCKET_COUNTER_UPDATE(state, payload) {
        console.log(payload);

        let be = 0;
        let ki = 0;
        let sum = 0;

        Object.keys(payload).forEach((l) => {
            if (Number.isNaN(payload[l].be)) return console.log("invalid payload be", payload[l]);
            if (Number.isNaN(payload[l].ki)) return console.log("invalid payload ki", payload[l]);

            payload[l].sum = payload[l].be - payload[l].ki;

            be = be + payload[l].be;
            ki = ki + payload[l].ki;
            sum += payload[l].sum;

            Vue.set(state.locations, l, payload[l]);
        });

        Vue.set(state.total, "be", be);
        Vue.set(state.total, "ki", ki);
        Vue.set(state.total, "sum", sum);

        console.log("Counter updated", state);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
