<template>
    <v-container id="home" fluid>
        <v-row align="center" justify="center">
            <v-col>
                <div v-if="$socket.connected">
                    <font style="font-size: 50vh; margin-left: auto; margin-right: auto; color: #fff" face="Arial">
                        {{ getSum() }}
                    </font>
                    <v-overlay opacity="0" id="overlay" absolute>
                        <v-card min-width="100vw" min-height="48vh" color="rgba(255, 0, 0, 0.0)" @click.native="up" elevation="0" class="test"></v-card><v-divider></v-divider>
                        <v-card min-width="100vw" min-height="48vh" color="rgba(0, 255, 0, 0.0)" @click.native="down" elevation="0"></v-card>
                    </v-overlay>
                </div>
                <v-btn v-else elevation="2" v-on:click="$root.login_dialog.open()">LOGIN</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Home",
    route: { path: "/", icon: "mdi-home", navigation: "" },
    methods: {
        getSum() {
            if (this.$store.state.counter.total.sum > 0) return this.$store.state.counter.total.sum;
            return 0;
        },
        up() {
            console.log("plus");
            this.$socket.client.emit("plus");
        },
        down() {
            console.log("minus");
            this.$socket.client.emit("minus");
        },
    },
};
</script>

<!-- elvileg a komplett home konténernek adunk háttérszínt, de valahogy mégsem tölti ki az oldalt -->
<style scoped>
#home {
    background-color: #333;
    height: 100%;
}
.row {
    height: 100%;
    margin: 0;
}
.v-divider {
    border-color: rgba(0, 0, 0, 0) !important;
}
.test {
    border-color: rgba(0, 0, 0, 0) !important;
}
</style>

<style>
#app {
    height: 100vh;
}
.v-application--wrap {
    height: 100%;
    display: inline !important;
}
#main {
    height: 100%;
    margin-top: 0px !important;
}
</style>

