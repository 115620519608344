// duplicate of database
// /@worker-modules/database/vue/database-components/headersByFormat.js
// TODO : move that logic there

// recursive path calculator
function add(headers, format, path) {
    // format must be an object
    if (typeof format !== "object") return;
    // check properties
    for (const i in format) {
        if (!format[i]) continue;
        // if it is a vuetiform style component
        if (format[i].is || format[i].label) {
            // create the object that we will add to the array
            let o = {};
            o.text = format[i].label || i;
            o.value = path.concat([i]).join(".");
            if (format[i].table === null) continue;
            const table = format[i].table;
            if (table) headers.push({ ...o, ...table });
            else headers.push(o);
            // the path is an array with the properties of the format
        } else add(headers, format[i], path.concat([i]));
    }
}

export default function headersByFormat(format) {
    // result array
    let headers = [];
    if (format) add(headers, format, []);
    else headers.push({ text: "Name", value: "name" });

    return headers;
}
