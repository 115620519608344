// initial state
const state = {
    // Deprecated
    //MAIN: {},
    //DATA: {},
};

// getters
const getters = {};

// actions
const actions = {
    connect: function (context) {
        var socket = this._vm.$socket;
        if (!socket.connected) socket.client.open();
    },
    // 
    disconnect: function (context) {
        var socket = this._vm.$socket;
        socket.client.close();
    },
};

// mutations
const mutations = {
    SOCKET_DISCONNECT(state) {
        // try to reconnect by script?
        let i;
        var socket = this._vm.$socket;
        const connect = () => {
            if (socket.connected) {
                console.log("reconnected ... ");
                return clearInterval(i);
            }
        };

        i = setInterval(connect, 250);
    },
    SOCKET_REDIRECT(state, path, target) {
        if (!target) target = "_self";
        window.open(path, target);
    },
    // Deprecated
    /*SOCKET_MAIN(state, main) {
        // the main message replaces the maiobject
        state.MAIN = main;
    },
    // Deprecated
    SOCKET_DATA(state, data) {
        // socket data is an additiv operation
        state.DATA = { ...state.DATA, ...data };
    },*/
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
