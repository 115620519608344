<template>
    <v-alert type="info" id="info" @click="click" icon="mdi-information">{{ info }}</v-alert>
</template>

<script>
export default {
    name: "vuetiform-info",
    props: ["value", "info", "link"],
    data: function () {
        return {};
    },
    methods: {
    	click() {
        	if (this.link) this.$router.push(this.link);
        }
    },
};

//<pre>{{ info }}</pre>
</script>
<style scoped>
#info {
    padding: 12;
    background-color: #eee;
    color: #333;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
