import Vue from "vue";

// initial state
const state = ß.TIMER_TYPES;

// mutations
const mutations = {};

const getters = {
    // Access with $store.getters["database/getDatabaseList"]
    getComponent: (state, getters) => (key) => {
        if (!key) return {};
        const element = ß.TIMER_TYPES[key].format;
        if (!element) return {};
        return element;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
