export default function getIdTimestampDate(id) {
    
    const timestamp = id.toString().substring(0, 8);
    const date = new Date(parseInt(timestamp, 16) * 1000);

    var month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;

    var day = date.getDate();
    if (day < 10) day = "0" + day;

    var hour = date.getHours();
    if (hour < 10) hour = "0" + hour;

    var min = date.getMinutes();
    if (min < 10) min = "0" + min;

    var sec = date.getSeconds();
    if (sec < 10) sec = "0" + sec;

    return date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
}