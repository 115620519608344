import Vue from "vue";

// initial state
const state = {
    collectors: [],
};

// mutations
const mutations = {
    SOCKET_DATAPOINTS_UPDATE_COLLECTORS(state, collectors) {
        Vue.set(state, "collectors", collectors);
    },
};

const getters = {
    // Access with $store.getters["database/getDatabaseList"]
    getDatapointCollectorComponent: (state, getters) => (key) => {
        if (!key) return {};
        if (!ß.DATAPOINT_COLLECTORS[key]) return {};
        const element = ß.DATAPOINT_COLLECTORS[key].format;
        if (!element) return {};
        return element;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
