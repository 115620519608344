<template>
    <div>
        <v-alert v-if="invalid > 0" type="error" id="info" @click="update()" icon="mdi-information" :key="'alert_' + key">
            {{ label || "ERROR" }} - ##&en list has ##&hu a listában ## {{ invalid }} ##&en invalid values. ##&hu érték érvénytelen. ##
        </v-alert>

        <v-autocomplete
            :key="'item_' + key"
            :menu-props="menu"
            v-model="model"
            :items="items"
            clearable
            multiple
            chips
            :label="getLabel()"
            :hint="hint"
            @input="update"
            open-on-clear
            :search-input.sync="search"
            @change="search = ''"
            :append-outer-icon="modeIcon()"
            @click:append-outer="mode()"
            :readonly="modes[x].ro"
            :prepend-icon="prepend"
            @click:prepend="$router.push(uribase)"
            :disabled="disabled"
        >
            <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" draggable @click="takeAction(data.item)" color="#ddd">
                    <v-avatar left>
                        <v-icon>{{ modeIcon() }}</v-icon>
                    </v-avatar>
                    {{ getText(data.item) }}
                </v-chip>
            </template>
            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title v-html="getText(data.item)"></v-list-item-title>
                </v-list-item-content>
            </template>
            <template v-slot:no-data> - </template>
        </v-autocomplete>
    </div>
</template>

<script>
// most of the code is the same as in vuetiform-list
// but this version is specialised to fully identified id'd

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}
export default {
    name: "vuetiform-universal-list",
    props: ["value", "items", "label", "hint", "uribase", "item-urikey", "sortable", "readonly", "prepend", "disabled"],
    data: function () {
        return {
            urikey: this["item-urikey"] || "db",
            model: Array.from(this.value || []),
            invalid: 0,
            modes: [{ action: "view", icon: "mdi-circle-outline", ro: true }],
            x: 0,
            menu: {
                closeOnClick: false,
                closeOnContentClick: false,
                disableKeys: true,
                openOnClick: false,
                maxHeight: 300,
                offsetY: true,
                offsetOverflow: true,
                transition: false,
            },
            search: "",
            key: 0,
        };
    },
    mounted() {
        this.getModes();
        this.setInvalid();
    },
    methods: {
        getText(item) {
            if (typeof item === "object")
                if (item.text !== undefined) {
                    if (item[this.urikey] !== undefined) return item[this.urikey] + " / " + item.text;
                    return item.text;
                }
            return item;
        },
        setInvalid() {
            const items = this.items.map((e) => {
                if (isObject(e)) if (e.value !== undefined) return e.value;
                return e;
            });
            const model = this.model.filter((e) => items.includes(e));

            this.invalid = this.model.length - model.length;
        },
        verifyItems() {
            // does not change the model, but sets the invalid counter, and returns the filtered model.
            const items = this.items.map((e) => {
                if (isObject(e)) if (e.value !== undefined) return e.value;
                return e;
            });

            const model = this.model.filter((e) => items.includes(e));

            return model;
        },
        getLabel() {
            if (this.invalid > 0) return this.label + " ( " + this.model.length + " / " + this.items.length + " ) - " + this.invalid + " invalid";
            return this.label + " ( " + this.model.length + " / " + this.items.length + " )";
        },
        getModes() {
            const modes = [];
            let ro = false;
            if (this.readonly) ro = true;

            if (this.uribase) modes.push({ action: "open", icon: "mdi-launch", ro });
            else if (ro) modes.push({ action: "open", icon: "mdi-circle-outline", ro: true });
            else modes.push({ action: "select", icon: "mdi-arrow-down-drop-circle", ro: false });

            if (this.sortable) {
                modes.push({ action: "left", icon: "mdi-arrow-left-box", ro: true });
                modes.push({ action: "right", icon: "mdi-arrow-right-box", ro: true });
            }

            modes.push({ action: "remove", icon: "mdi-close-circle", ro: true });

            //let prepend = null;
            //if (this.uribase) prepend = "mdi-launch";
            //this.prepend = prepend;
            this.modes = modes;
        },
        update() {
            // on update, return the filtered model
            const model = this.verifyItems();
            this.model = model;
            this.$emit("input", model);
            this.invalid = 0;
            this.key++;
        },
        mode() {
            this.x = (this.x + 1) % this.modes.length;
        },
        modeIcon() {
            return this.modes[this.x].icon;
        },
        takeAction(item) {
            const action = this.modes[this.x].action;
            if (this[action]) return this[action](item);
        },
        open(item) {
            if (!this.uribase) return;
            if (item[this.urikey]) this.$router.push(this.uribase + "/" + item[this.urikey] + "/" + item.value);
            else this.$router.push(this.uribase + "/" + item.value);
        },
        remove(item) {
            const index = this.model.indexOf(item.value);
            this.model.splice(index, 1);
            this.key++;
            this.update();
        },
        left(item) {
            const index = this.model.indexOf(item.value);
            if (index < 1) return;
            const hold = this.model[index - 1];
            this.model[index - 1] = item.value;
            this.model[index] = hold;
            this.key++;
            this.update();
        },
        right(item) {
            const index = this.model.indexOf(item.value);
            if (index + 1 > this.model.length - 1) return; //console.log("?");
            const hold = this.model[index + 1];
            this.model[index + 1] = item.value;
            this.model[index] = hold;
            this.key++;
            this.update();
        },
    },
    watch: {
        uribase() {
            this.getModes();
            this.key++;
        },
        value() {
            //this.verifyItems();
            //console.log("value");
            this.setInvalid();
            this.key++;
        },
        items() {
            //this.verifyItems();
            //console.log("items");
            this.setInvalid();
            this.key++;
        },
        model() {
            //this.verifyItems();
            //console.log("model");
            this.setInvalid();
            this.key++;
        },
    },
};
</script>
<style scoped></style>
