<template>
    <v-row>
        <v-col cols="6">
            <v-menu
                ref="time"
                v-model="timemenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="time" :label="label" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker
                    v-if="timemenu"
                    v-model="time"
                    full-width
                    @click:minute="
                        $refs.time.save(time);
                        update();
                    "
                    format="24hr"
                ></v-time-picker>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import * as datelib from "@/vuetiform-components/vuetiform-datelib.js";

export default {
    name: "vuetiform-daytime",
    props: ["value", "label", "readonly"],
    data: function () {
        let date = new Date();
        if (this.value) {
            date = new Date(this.value);
        }
        return {
            time: datelib.timepickerDate(date),
            timemenu: false,
        };
    },
    mounted() {
    	this.update();
    },
    methods: {
        update() {
            let d = new Date(0);
            d.setHours(this.time.split(":")[0], this.time.split(":")[1], 0, 0);
            if (this.readonly === true) return;
            this.$emit("input", d);
        },
    },
};
</script>