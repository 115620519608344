// this is an automatically generated file from the ßoilerplate framework

// /srv/codepad-project/@vue-modules/app/vue;
import component_1 from '@/global-components/global-component.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import component_2 from '@/App.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import component_3 from '@/keyboard-events.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import component_4 from '@/main.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import component_5 from '@/navigation.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import component_6 from '@/notification-snackbars.vue';

// /srv/codepad-project/porta-modules/app/vue;
import component_7 from '@/home.vue';

// /srv/codepad-project/porta-modules/app/vue;
import component_8 from '@/table.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_9 from '@/login-components/FormForEmail.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_10 from '@/login-components/FormForName.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_11 from '@/login-components/FormForPassword.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_12 from '@/login-components/LoginCardEmailLogin.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_13 from '@/login-components/LoginCardEmailSettings.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_14 from '@/login-components/LoginCardName.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_15 from '@/login-components/LoginCardPasswordLogin.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_16 from '@/login-components/LoginCardPasswordSettings.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_17 from '@/login-components/LoginCardSettings.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_18 from '@/login-components/LoginDialog.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_19 from '@/login-components/LoginLayout.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import component_20 from '@/login-components/LoginSelector.vue';

// /srv/codepad-project/@vue-modules/multilanguage/vue;
import component_21 from '@/multilanguage-components/LanguageSelector.vue';

// /srv/codepad-project/@system-modules/checkpoints/vue;
import component_22 from '@/checkpoint-components/checkpoint-routes.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_23 from '@/database-components/database-card.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_24 from '@/database-components/database-csv-download.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_25 from '@/database-components/database-csv-upload-table.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_26 from '@/database-components/database-csv-upload.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_27 from '@/database-components/database-dialog-table.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_28 from '@/database-components/database-document-dialog.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_29 from '@/database-components/database-json-download.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_30 from '@/database-components/database-json-upload.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_31 from '@/database-components/database-table.vue';

// /srv/codepad-project/@worker-modules/database/vue;
import component_32 from '@/database.vue';

// /srv/codepad-project/@fox-modules/fox/vue;
import component_33 from '@/f0x-actions.vue';

// /srv/codepad-project/@fox-modules/fox/vue;
import component_34 from '@/f0x-barcode.vue';

// /srv/codepad-project/@fox-modules/fox/vue;
import component_35 from '@/f0x-ble.vue';

// /srv/codepad-project/@fox-modules/fox/vue;
import component_36 from '@/f0x-nfc.vue';

// /srv/codepad-project/@fox-modules/fox/vue;
import component_37 from '@/f0x-photo.vue';

// /srv/codepad-project/@fox-modules/fox/vue;
import component_38 from '@/f0x-session.vue';

// /srv/codepad-project/@fox-modules/fox/vue;
import component_39 from '@/f0x-settings.vue';

// /srv/codepad-project/@fox-modules/fox/vue;
import component_40 from '@/f0x.vue';

// /srv/codepad-project/@worker-modules/datapoints/vue;
import component_41 from '@/datapoints-components/datapoints-table.vue';

// /srv/codepad-project/@worker-modules/datapoints/vue;
import component_42 from '@/datapoints.vue';

// /srv/codepad-project/@vue-modules/vuetifiles/vue;
import component_43 from '@/vuetifiles-components/vuetifiles-list.vue';

// /srv/codepad-project/@vue-modules/vuetifiles/vue;
import component_44 from '@/vuetifiles-components/vuetifiles-upload.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_45 from '@/vuetiform-components/vuetiform-checkboxes.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_46 from '@/vuetiform-components/vuetiform-chipgroup.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_47 from '@/vuetiform-components/vuetiform-color.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_48 from '@/vuetiform-components/vuetiform-comment.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_49 from '@/vuetiform-components/vuetiform-database-datafield.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_50 from '@/vuetiform-components/vuetiform-date-and-time.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_51 from '@/vuetiform-components/vuetiform-daytime.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_52 from '@/vuetiform-components/vuetiform-designate.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_53 from '@/vuetiform-components/vuetiform-duration.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_54 from '@/vuetiform-components/vuetiform-element.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_55 from '@/vuetiform-components/vuetiform-info.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_56 from '@/vuetiform-components/vuetiform-list.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_57 from '@/vuetiform-components/vuetiform-radios.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_58 from '@/vuetiform-components/vuetiform-select.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_59 from '@/vuetiform-components/vuetiform-taggable-text.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_60 from '@/vuetiform-components/vuetiform-timestamp.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_61 from '@/vuetiform-components/vuetiform-universal-select.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import component_62 from '@/vuetiform-components/vuetiform.vue';


export default {component_1, component_2, component_3, component_4, component_5, component_6, component_7, component_8, component_9, component_10, component_11, component_12, component_13, component_14, component_15, component_16, component_17, component_18, component_19, component_20, component_21, component_22, component_23, component_24, component_25, component_26, component_27, component_28, component_29, component_30, component_31, component_32, component_33, component_34, component_35, component_36, component_37, component_38, component_39, component_40, component_41, component_42, component_43, component_44, component_45, component_46, component_47, component_48, component_49, component_50, component_51, component_52, component_53, component_54, component_55, component_56, component_57, component_58, component_59, component_60, component_61, component_62, };