<template>
    <div>
        <p class="text-justify">{{ label || "##&en Color ##&hu Szín ##" }} COLOR {{ color }}</p>
        <v-color-picker dot-size="25" swatches-max-height="200" v-model="color" @input="update" mode="hexa"></v-color-picker>
    </div>
</template>

<script>
export default {
    name: "vuetiform-color",
    props: ["value", "label", "default"],
    data: function () {
        let color = this.value || this.default || "#FFFFFF00";
        if (color.length !== 9) color =  "#FFFFFF00";
        return {
            color,
        };
    },
    methods: {
        update() {
            this.$emit("input", this.color);
        },
    },
};
</script>
<style scoped>
#label {
    margin-bottom: 0;
}
</style>
