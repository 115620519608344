<template>
    <v-app id="app" data-app>
        <login_dialog ref="login_dialog" />
        <div v-if="!is_user()" id="login_layout_frame" class="center"><login_layout /></div>
        <div v-else>
            <div v-if="$socket.connected">
                <app_navigation />
                <app_main />
            </div>
            <div v-else>
                <br /><br />
                <v-card class="center" max-width="400">
                    <v-alert id="alert" color="red" icon="mdi-server" transition="fade-transition" class="text-sm-left">##&en CONNECTING ... ##&hu KAPCSOLÓDÁS ... ##</v-alert>
                </v-card>
            </div>
        </div>
    </v-app>
</template>

<script>
import app_navigation from "@/navigation.vue";
import app_main from "@/main.vue";
import login_layout from "@/login-components/LoginLayout.vue";
import login_dialog from "@/login-components/LoginDialog.vue";

export default {
    name: "app",
    data: function () {
        return {};
    },
    components: {
        app_navigation,
        app_main,
        login_layout,
        login_dialog,
    },
    methods: {
        is_user() {
            //if (!this.$store) return false;

            //if (!this.$store.state) return false;
            //if (!this.$store.state.server.session) return false;
            if (this.$store.state.server.session.user) return true;

            return false;
        },
    },
    mounted() {
        this.$root.login_dialog = this.$refs.login_dialog;
    },
    watch: {
        "$store.state.server.session.user.theme"(nv) {
            if (nv) this.$vuetify.theme.themes.light.primary = nv;
        },
    },
};
</script>

<style scoped>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

#login_layout_frame {
    width: 400px;
    max-width: 100%;
    border-radius: 4px;
    background-color: powderblue;
    margin-top: 0;
}
#login_layout_frame > div {
    overflow: hidden;
    border-radius: 5px;
}
.center {
    margin: auto;
    width: 50%;
}
</style>
