<template>
    <div>
        <v-btn class="mx-2" fab color="primary" :loading="isSelecting" @click="onButtonClick"><v-icon> mdi-upload-outline </v-icon></v-btn>
        <input ref="uploader" class="d-none" type="file" accept=".json" @change="onFileChanged" />

        <v-dialog v-model="dialog" scrollable>
            <v-card>
                <v-card-title>
                    <h2 class="text-uppercase database-upload-title">CSV Import {{ items.length }} {{ db }}</h2>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false"><v-icon> mdi-close </v-icon></v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <database-csv-upload-table :db="db" v-model="items_preview" />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" fab color="primary" @click="sendData()"> <v-icon> mdi-database-check </v-icon> </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as axios from "__axios";

import exchanger from "@/database-components/database-exchanger.js";
import DatabaseCsvUploadTable from "@/database-components/database-csv-upload-table.vue";

export default {
    name: "database-json-upload",
    props: ["db"],
    data() {
        return {
            selectedFile: null,
            isSelecting: false,
            dialog: false,
            items: [],
            items_preview: [],
        };
    },
    components: { DatabaseCsvUploadTable },
    methods: {
        onButtonClick() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );

            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            let _this = this;
            this.selectedFile = e.target.files[0];

            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => _this.processData(e.target.result);
            reader.readAsText(file);
        },
        processData(data) {
            let result = JSON.parse(data);
            this.items_preview = exchanger(Object.values(result), this, true);
            this.items = Object.values(result);
            this.dialog = true;
        },
        sendData() {
            let _this = this;

            let payload = {};
            payload.db = this.db;
            payload.op = "save";
            payload.documents = this.items;

            this.$socket.client.emit("database-operation", payload, function ({ messages }) {
                if (messages)
                    if (messages.length > 0) {
                        alert(messages);
                        return;
                    }
                _this.dialog = false;
            });
        },
    },
};
</script>
