<template>
    <div></div>
</template>

<script>
// https://stackoverflow.com/questions/38422076/how-to-set-keyup-on-whole-page-in-vue-js
// on the page you want to use that component you'd add
// <keyboard-events v-on:keypress="keyboardEvent"></keyboard-events>
/*
methods: {
  keyboardEvent (e) {
        console.log(e);
        console.log(e.altkey, e.code, e.ctrlKey, e.isComposing, e.key, e.location, e.metaKey, e.repeat, e.shiftKey);
  }
}
*/
export default {
    created() {
        const component = this;
        this.keyup = function (e) {
            component.$emit("keyup", e);
        };
        this.keydown = function (e) {
            component.$emit("keydown", e);
        };
        this.keypress = function (e) {
            component.$emit("keypress", e);
        };
        window.addEventListener("keyup", this.keyup);
        window.addEventListener("keydown", this.keydown);
        window.addEventListener("keypress", this.keypress);
    },
    beforeDestroy() {
        window.removeEventListener("keyup", this.keyup);
        window.removeEventListener("keydown", this.keydown);
        window.removeEventListener("keypress", this.keypress);
    },
};
</script>

<style scoped>
/*div {
  display: none;
}*/
</style>
