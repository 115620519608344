import { render, staticRenderFns } from "./vuetiform-taggable-text.vue?vue&type=template&id=69e75265&scoped=true&"
import script from "./vuetiform-taggable-text.vue?vue&type=script&lang=js&"
export * from "./vuetiform-taggable-text.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../@vue-modules/vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69e75265",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../@vue-modules/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VSelect,VTextarea})
